<template>
  <div>
    <dx-data-grid
      v-if="true"
      id="productInventoryHistory"
      ref="productInventoryHistoryRef"
      :data-source="dataSource"
      height="55vh"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :hover-state-enabled="true"
      :row-alternation-enabled="false"
      :column-auto-width="true"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-sorting mode="single" />
      <dx-remote-operations :paging="true" :sorting="true" :filtering="false" />
      <template #filterToolbar />
      <dx-column data-field="receivedAt" data-type="date" />
      <dx-column data-field="receivedBy" />
      <dx-column data-field="modifiedAt" data-type="date" />
      <dx-column data-field="modifiedBy" />
      <dx-column data-field="tracking" caption="Tracking" />
      <dx-column data-field="orderNumber" caption="Order Number" :width="150" />
      <dx-column data-field="palletId" caption="Pallet ID" />
      <dx-column data-field="itemAction" caption="Action" alignment="center" cell-template="itemActionCell" width="120" />
        <template #itemActionCell="{ data }">
          <span class="d-block badge" :class="getItemActionFormated(data.value).variant">
            {{ getItemActionFormated(data.value).text }}
          </span>
        </template>
      <dx-column data-field="msku" caption="MSKU" :width="350" />
      <dx-column data-field="fnsku" caption="FNSKU" :width="120" />
      <dx-column data-field="serialNo" caption="Serial No" :width="120" />
      <dx-column data-field="expirationDate" caption="Exp Date" data-type="date" :width="120" />
      <dx-column data-field="quantity" caption="Quantity" :width="100" />
      <dx-column data-field="reserved" caption="Reserved" :width="100" />
      <dx-column data-field="sentCount" caption="Shipped" cell-template="cellShippedFormat" :width="100" />
      <template #cellShippedFormat="{ data }">
        <span class="d-block badge" :class="getShippedFormated(data)">
          {{ data.value }}
        </span>
      </template>
      <dx-column data-field="notes" caption="Notes" />
      <dx-paging :page-size="pagerOptions.pageSize" />
      <dx-pager
        :allowed-page-sizes="pagerOptions.allowedPageSizes"
        :show-info="pagerOptions.showInfo"
        :show-navigation-buttons="pagerOptions.showNavigationButtons"
        :show-page-size-selector="pagerOptions.showPageSizeSelector"
        :visible="pagerOptions.visible"
      />
    </dx-data-grid>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate } from '@core/utils/filter'
import inventoryService from '@/http/requests/inventory/inventoryService.js'
import { productInventoryDataSource } from './productInventoryHistoryStore'

export default {
  /* eslint-disable no-unused-vars */
  mixins: [GridBase],
  props: {
    productId: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      dataSource: productInventoryDataSource,
    }
  },
  computed: {
  },
  watch: {
    productId: {
      handler() {
        this.load()
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.dataSource.searchValue(this.productId)
      this.dataSource.load()
    },
    getShippedFormated(data) {
      if (data.data.quantity === 0) return 'badge-light-success'
      return 'badge-light-warning'
    },
    getItemActionFormated(itemAction) {
      if (!itemAction) return { variant: 'badge-light-danger', text: 'Unknown' }
      if (itemAction === 'FBA') return { variant: 'badge-light-success', text: 'FBA' }
      if (itemAction === 'FBM') return { variant: 'badge-light-primary', text: 'FBM' }
      if (itemAction === 'DROPSHIP') return { variant: 'badge-light-warning', text: 'Dropship' }
      if (itemAction === 'FORWARDING') return { variant: 'badge-light-warning', text: 'Forwarding' }
      return { variant: 'badge-light-secondary', text: itemAction }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          type: 'primary',
          elementAttr: {
            class: 'mx-half',
          },
          stylingMode: 'outlined',
          hint: 'Download Product Inventory History',
          onClick: () => {
            this.onDownloadItems()
          },
        },
        location: 'after',
      })
    },
    async onDownloadItems(e) {
      const pager = new Pager({})
      const max = 2000
      pager.setPageNumber(0, max)
      const pageableQuery = `${pager.toCreatePageable()}`
      const response = await inventoryService.fetchItemsByProductId({ productId: this.productId }, pageableQuery)
      const data = response.data.content
      if (data === undefined || data === null || data?.length <= 0) {
        Notify.warning('There are not any items to download.')
        return
      }
      this.onDownloadExportData(data)
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Product Outbound History')
      worksheet.columns = [
        { header: 'Order Number', key: 'orderNumber', width: 18 },
        { header: 'Action', key: 'itemAction', width: 18 },
        { header: 'MSKU', key: 'msku', width: 18 },
        { header: 'FNSKU', key: 'fnsku', width: 18 },
        { header: 'Serial No', key: 'serialNo', width: 18 },
        { header: 'Expiration Date', key: 'expirationDate', width: 18 },
        { header: 'Quantity', key: 'quantity', width: 18 },
        { header: 'Reserved', key: 'reserved', width: 18 },
        { header: 'Shipped', key: 'sentCount', width: 18 },
        { header: 'Notes', key: 'notes', width: 18 },
      ]

      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.orderNumber,
          item.itemAction,
          item.msku,
          item.fnsku,
          item.serialNo,
          formatDate(item.expirationDate),
          item.quantity,
          item.reserved,
          item.sentCount,
          item.notes,
        ])
      })

      workbook.xlsx.writeBuffer().then(buffer => {
        const fileName = Date.now()
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `InventoryHistory-${fileName}.xlsx`,
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
